"use client";
import Image from "next/image";
import React, { useEffect, useRef, useState } from "react";
import cardImg from "../../assets/flightsHome/new-york-city-evening-NYCTG0221-52492d6ccab44f328a1c89f41ac02aea 1.png";
import globalAxiosURL from "@/hooks/globalAxiosURL";
import { useRouter } from "next/navigation";
import { useDispatch } from "react-redux";
import { setFlight } from "@/redux/flightsSlice";

const SpecialOffers = () => {
  const dispatch = useDispatch();
  // const [allPlaces, setAllPlaces] = useState([]);
  const route = useRouter();

  const places = [
    "Dhaka",
    "Egypt",
    "Sydney",
    "Italy",
    "Canada",
    "New York",
    "Dubai",
    "The British Museum",
    "Angkor Wat, Cambodia",
    "Bejning, China",
  ];

  const handleSendData = () => {};

  const [flights, setFlights] = useState([]);

  // console.log(flights);

  // ---- To Extract the routes ----

  // const routeArray = flights.flatMap((flight) => [
  //   flight.route.origin,
  //   flight.route.destination,
  // ]);

  // const uniqueRoutes = [...new Set(routeArray)];

  // console.log("unique Array", uniqueRoutes);

  // console.log(routeArray);

  const [isLoading, setIsLoading] = useState(true);
  const axios = globalAxiosURL();
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const [value, setValue] = useState("");

  const handleInputChange = (e) => {
    setValue(e.target.value);
  };

  const handleSetValue = (place) => {
    setValue(place);
    setIsOpen(false);
  };

  const [isOpen, setIsOpen] = useState(false);
  // console.log(isOpen);
  // console.log(flights);

  useEffect(() => {
    axios
      .get(`/flights?place=${value}`)
      .then((response) => {
        setFlights(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    setIsLoading(false);
  }, [axios, value]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const response = await axios.get(`/flights?place=`);
  //     console.log("response", response.data);
  //     // const allPlaces = [
  //     //   ...new Set(response.data.map(route => route.route.origin.))
  //     // ]
  //   };

  //   fetchData();
  // }, [axios]);

  const handleFetchValue = (e) => {
    e.preventDefault();
    // console.log(e.target.place.value);
    // const place = e.target.place.value;
    // // setReload(!reload);

    // const results = flights.filter(
    //   (flight) =>
    //     flight.route.origin.includes(place) ||
    //     flight.route.destination.includes(place)
    // );

    // setFlights(results);

    // console.log(results);
  };

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickCards = (flight) => {
    dispatch(setFlight(flight));

    const arrivalAirportsArray = [];
    const departureAirportsArray = [];
    const dateArray = [];

    arrivalAirportsArray.push(flight.route.destination.airportCode);
    departureAirportsArray.push(flight.route.origin.airportCode);
    dateArray.push(flight.date);

    localStorage.setItem(
      "departureAirports",
      JSON.stringify(departureAirportsArray)
    );
    localStorage.setItem(
      "arrivalAirports",
      JSON.stringify(arrivalAirportsArray)
    );
    localStorage.setItem("departureDates", JSON.stringify(dateArray));
    localStorage.setItem("show", true);

    route.push("/pages/searchResult", {
      depurtureAirports: departureAirportsArray,
    });
  };

  if (isLoading) {
    return;
  }

  return (
    <div className="mb-16">
      <h3 className="text-center max-w-[950px] w-[80%] mx-auto font-semibold text-lg md:text-2xl lg:text-[28px] leading-relaxed mb-6">
        Discover the price that the airlines are trying to hide from you. We're
        offering you airfare price breaks so you can save cash.
      </h3>
      <p className="text-center font-medium text-lg mb-4 lg:mb-0">
        Take a look
      </p>
      <form
        onSubmit={handleFetchValue}
        className="flex justify-center lg:justify-end"
      >
        <div className="relative">
          <input
            type="text"
            ref={inputRef}
            name="place"
            onFocus={() => setIsOpen(true)}
            value={value}
            onChange={handleInputChange}
            placeholder="Enter Location"
            className="bg-[#D9D9D9] relative p-[6px] px-2 md:px-4 text-sm md:text-base outline-none placeholder:text-gray-600 border-none rounded-sm"
          />
          <button className="ml-1 bg-green-700 hover:bg-green-800 duration-200 text-white font-medium p-[6px] px-2 md:px-4 rounded-sm text-sm md:text-base active:scale-95">
            Search Deals
          </button>
          <div>
            {isOpen && (
              <ul
                ref={dropdownRef}
                className="absolute top-full w-full max-h-[300px] overflow-y-auto bg-white border shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px] z-50 rounded-b-md mt-1"
              >
                {places.map((place, idx) => (
                  <li
                    key={idx}
                    onClick={() => handleSetValue(place)}
                    className="px-3 py-2 border-b hover:bg-gray-100 cursor-default"
                  >
                    {place}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </form>
      {flights.length < 1 ? (
        <div className="text-center my-20 text-xl font-medium">
          No flight available!
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 lg:gap-12 lg:gap-y-10 mt-8 w-[95%] mx-auto md:w-full">
          {flights.map((flight) => (
            <div
              key={flight._id}
              onClick={() => handleClickCards(flight)}
              className="bg-[#D9D9D980] hover:bg-[#D9D9D9] duration-200 relative rounded-[20px] w-full group/edit cursor-pointer"
            >
              <div className="rounded-[20px] overflow-hidden h-60 w-full">
                <Image
                  alt={flight.route}
                  width={700}
                  height={700}
                  src={flight.image || cardImg}
                  className="h-full w-full object-cover group-hover/edit:scale-105 duration-300"
                />
              </div>
              <h3 className="py-2 text-center md:text-base text-sm px-2">
                {flight.route.origin.city} to {flight.route.destination.city} on{" "}
                {new Date(flight.date).toLocaleDateString("en-GB", {
                  day: "numeric",
                  month: "long",
                })}
              </h3>
              <div className="absolute top-4 right-4 bg-[#009D4D] font-medium text-white rounded-[10px] lg:text-sm px-4 py-1">
                BDT {flight.price}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SpecialOffers;
