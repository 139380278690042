"use client";
import TravelarCounter from "@/components/homeComponent/banner/TravelarCounter";
import { IoIosSearch } from "react-icons/io";
import { LiaPlaneDepartureSolid } from "react-icons/lia";
import usBangla from "../../../assets/flightsHome/usBangla.png";
import bimanbd from "../../../assets/flightsHome/biman-bangla.png";
import emirates from "../../../assets/flightsHome/emirates.png";
import qatarAirways from "../../../assets/flightsHome/qatar-airways.png";
import singaporeAir from "../../../assets/flightsHome/singapore-airlines.png";
import Onway from "@/components/homeComponent/banner/OnWay";
import RoundWay from "@/components/homeComponent/banner/Return";
import Multicity from "@/components/homeComponent/banner/Multicity";
import Image from "next/image";
import { useState } from "react";

const FlightBanner = () => {
  const [oneway, setOneway] = useState(true);
  const [roundway, setRoundway] = useState(false);
  const [multicity, setMulticity] = useState(false);

  const oneWay = () => {
    setRoundway(false);
    setMulticity(false);
    setOneway(true);
  };
  const roundWay = () => {
    setOneway(false);
    setMulticity(false);
    setRoundway(true);
  };
  const multi = () => {
    setOneway(false);
    setRoundway(false);
    setMulticity(true);
  };
  return (
    <div
      style={{
        backgroundImage: `url('/banner.png')`,
      }}
      className="h-auto  lg:min-h-[620px] bg-cover bg-center bg-no-repeat relative w-full"
    >
      <span className="absolute w-full h-full bg-black/70"></span>
      <div className="relative flex flex-col items-center pt-16 pb-8 justify-center z-50 px-3 md:px-7">
        <div className="text-white text-center mt-5 space-y-4">
          <h1 className="lg:text-4xl md:text-2xl text-lg font-semibold lg:block md:block hidden">
            Finds a cheapest flight with right price
          </h1>
          <p className="lg:text-lg md:text-sm lg:block md:block hidden font-medium">
            Best Flight deal to around the globe
          </p>
          <div className="w-full sm:w-2/3 lg:w-auto bg-black/50 mx-auto py-6 px-4 sm:px-8 rounded">
            <div className="md:w-[80%] lg:w-full mx-auto">
              <div>
                <div>
                  <div className="flex mb-5 gap-2 md:gap-7 justify-between md:justify-center">
                    <div className="flex items-center gap-1 relative cursor-pointer">
                      <input
                        onClick={oneWay}
                        value="oneway"
                        defaultChecked
                        type="radio"
                        name="transport"
                        id="oneway"
                      />
                      <p className="text-xs md:text-sm text-white font-medium">
                        One Way
                      </p>
                    </div>

                    <div className="flex items-center gap-1 relative cursor-pointer">
                      <input
                        onClick={roundWay}
                        value="Round way"
                        type="radio"
                        name="transport"
                        id="round"
                      />
                      <p className="text-xs md:text-sm text-white font-medium text-center">
                        Round Way
                      </p>
                    </div>

                    <div className="flex items-center gap-1 relative cursor-pointer">
                      <input
                        onClick={multi}
                        value="Multi city"
                        type="radio"
                        name="transport"
                        id="multi"
                      />
                      <p className="text-xs md:text-sm text-white font-medium ">
                        Multi City
                      </p>
                    </div>
                  </div>
                  <div>
                    <TravelarCounter />
                  </div>
                </div>
                {oneway && <Onway />}
                {roundway && <RoundWay />}
                {multicity && <Multicity />}
              </div>
              {/* <div className="text-left flex items-center gap-2">
                <input type="checkbox" name="" id="" />{" "}
                <span>Check hotels with HAMS</span>
              </div> */}
              <div className="flex mt-5 md:ml-2 gap-1 md:gap-7">
                {/* <div className="flex items-center gap-1 relative cursor-pointer">
                  <input
                    value="nearby airports"
                    // defaultChecked
                    type="radio"
                    name="transport"
                    id="nearby airports"
                  />
                  <p className="text-sm text-white font-medium ">
                    Nearby Airports
                  </p>
                </div> */}

                {/* <div className="flex items-center gap-1 relative cursor-pointer">
                  <input
                    value="Prefer nonstop"
                    type="radio"
                    name="transport"
                    id="Prefer nonstop"
                  />
                  <p className="text-sm text-white font-medium text-center">
                    Prefer nonstop
                  </p>
                </div> */}
              </div>
            </div>
          </div>

          <p className="lg:text-lg md:text-sm lg:block md:block hidden font-medium max-w-[500px] mx-auto">
            Discover with HAMS more than thousand of partners to bring you
            better travel deals.
          </p>

          <div className="flex flex-wrap items-center justify-center gap-8 w-[90%] mx-auto md:w-full">
            <Image alt="us-bangla" src={usBangla} />
            <Image alt="us-bangla" src={emirates} />
            <Image alt="us-bangla" src={singaporeAir} />
            <Image alt="us-bangla" src={qatarAirways} />
            <Image alt="us-bangla" src={bimanbd} />
          </div>
        </div>
      </div>
      {/* <LoginMsg /> */}
    </div>
  );
};

export default FlightBanner;
